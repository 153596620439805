import React from 'react';
import './TermAndConditionsOfUse.css';

const TermsAndConditionsOfUse = () => {
    return (
        <div className="terms">

            <h1 className="title-text">Service Terms and  Conditions of Use</h1>
            <p className="mini-text">By using our service, you agree to comply with and be bound by our terms and conditions of use together with our privacy policy and any other documents referred to therein. If you do not agree to these Terms of Use, you must not use our service.</p>
            <p className="mini-text">We reserve the right to amend these Terms of Use from time to time. Any changes will be posted on our Websites (malaaz.online and Malaaz.dz). Your continued use of our service after posting will constitute your acceptance of, and agreement to, any changes. Specific services that we offer, or make available to you, may also be governed by additional or alternative terms and conditions with us, or other third parties.</p>

            <h2 className="sub-text">Registering on our Service</h2>
            <p className="mini-text">You must be 18 years old or over to create an account on our service and to contact our partners through our Websites, and warrant that the details you provide on registration are true, accurate, complete and current. You must promptly update us with any changes to your account details. You must create a password when you register and you are responsible for maintaining the confidentiality of that password. You agree that any person to whom your username or password is disclosed is authorised to act as your agent for the purpose of transacting for any services via our service, and you will be responsible for any and all activity conducted using your password. You shall take reasonable steps to prevent unauthorised access to your account via any computer or other device that you use to access your account. If you believe someone has, without authorisation, accessed an account that you created on our service, please contact us immediately.</p>
            <p className="mini-text">When you create an account you may be given the opportunity to opt-in to receive marketing emails from us, our group companies or third parties. You can manage your email subscription preferences via our service or unsubscribe from emails at any time. From time to time, we may need to send you service emails relating to your accounts, and we reserve the right to do so.</p>

            <h2 className="sub-text">Your responsibilities</h2>
            <p className="mini-text">The content on our service is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our service. In particular, please note that we are not an estate agency. Our service provide a platform where estate agents and individuals may market and you may view property details. The estate agents and the individuals that post the Details are solely responsible for their accuracy and for fielding enquiries relating to those properties. We do not participate in any communications between you and estate agents or individuals nor in any part of a subsequent property transaction. We host the Details in good faith but we have not verified them and we make no representations, warranties or guarantees, whether express or implied about the Details. We are also not giving you any advice (investment, financial or otherwise) in respect of any content on our service or recommending or endorsing any properties, products or content on our service.</p>

            <h2 className="sub-text">Materials you provide</h2>
            <p className="mini-text">By submitting content on our service, you grant us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, sub-licensable and fully transferable licence to use, reproduce, display, sell, modify and edit the Content. You waive any moral rights you may have in the Content. We will not pay you any fees for the Content and reserve the right in our sole discretion to remove or edit it at any time. You warrant and represent that you have all rights necessary to grant us these rights. We will permit you to post Content on our service in accordance with our procedures and provided that the content is not illegal, obscene, abusive, threatening, defamatory or otherwise objectionable to us. Any personal data that you provide via, or in connection with, the service will be governed by privacy policy.</p>

            <h2 className="sub-text">Disclaimer and liability</h2>
            <p className="mini-text">The service including all content on it is provided by us on an "as is" and "as available" basis and we make no representations, guarantees or warranties of any kind, either express or implied, including without limitation, warranties as to quality, suitability for any purpose, compatibility, reliability, accuracy, completeness, timeliness, access or use. It is your responsibility to evaluate the quality, suitability, accuracy, completeness and reliability of our service and any information or content contained therein. We accept no responsibility and make no representations, guarantees, or warranties that the service will operate continuously, without interruptions or be fault-free. We may need to make the service unavailable with or without notice to carry out maintenance or upgrade work. We accept no liability for any interruption or loss of service.</p>
            <p className="mini-text">Any value estimates provided on our service are intended for general interest and information purposes only and should not be relied upon for any commercial transaction or similar use. These estimates are based on publicly available information which may be inaccurate or incomplete, and typical factors in certain locations. They will not take account of any factors which are unknown to us and should only be used as a general estimate. None of the information available on our service is intended to be a substitute for independent professional advice and users are recommended to seek advice from suitably qualified professionals such as surveyors and solicitors if relevant to their particular circumstances. We shall not be liable for any losses suffered as a result of relying on our value estimates.</p>
            <p className="mini-text">Property descriptions and other information provided on our service are intended for information and marketing purposes and, whilst displayed in good faith, we will not in any circumstances accept responsibility for their accuracy. The property advert pages on our service do not constitute property particulars and these should be available directly from the agent or individual marketing the property. It is the responsibility of prospective buyers/tenants to satisfy themselves as to the accuracy of any property descriptions displayed and the responsibility of agents or individuals to ensure the accuracy and integrity of property descriptions provided on our service and in any property particulars.</p>
            <p className="mini-text">To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our service or any content on it, whether express or implied.</p>
            <p className="mini-text">We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
            <p className="mini-text">Use of, or inability to use, our service; or use of or reliance on any content displayed on our service.</p>
            <p className="mini-text">If you are a business user, please note that in particular, we will not be liable for: loss of profits, sales, business, or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or any indirect or consequential loss or damage.</p>
            <p className="mini-text">If you are a consumer user, please note that we only provide our service for domestic and private use. You agree not to use our service for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
            <p className="mini-text">We assume no responsibility for the content of websites linked to on our service. Such links should not be interpreted as endorsement by us of those linked service. We will not be liable for any loss or damage that may arise from your use of them.</p>

            <h2 className="sub-text">Restrictions on Use</h2>
            <p className="mini-text">You agree not to:</p>
            <p className="mini-text">• transmit any material designed to interrupt, damage, destroy or limit the functionality of our service</p>
            <p className="mini-text">• use any automated software to view our service without consent and to only access our service manually</p>
            <p className="mini-text">• use our Websites other than for your own personal use or as an agent or an individual listing properties for sale and to rent</p>
            <p className="mini-text">• attempt to copy our data or reverse engineer our processes</p>
            <p className="mini-text">• use our service in any manner that is illegal, immoral or harmful to us (including to commit or facilitate the commitment of any fraud against us)</p>
            <p className="mini-text">• use our service in breach of any policy or other notice on our service;</p>
            <p className="mini-text">• remove or alter any copyright notices that appear on our service</p>
            <p className="mini-text">• publish any material that may encourage a breach of any relevant laws or regulations;</p>
            <p className="mini-text">• interfere with any other user’s enjoyment of our service</p>
            <p className="mini-text">• transmit materials protected by copyright without the permission of the owner; or conduct yourself in an offensive or abusive manner whilst using our Websites.</p>
            <p className="mini-text">• We shall be entitled to suspend the service and/or terminate these Terms of Use without notice to you if you commit a breach of these Terms and conditions of Use, and in particular these restrictions on use, which in our reasonable opinion is serious enough to merit immediate termination.</p>
            <h2 className="sub-text">General</h2>
            <p className="mini-text">he headings in these Terms and Conditions of Use are solely used for convenience only. You may not assign or delegate any or all of your rights or obligations in this Agreement. We may assign this Agreement at any time without notice to you. Our delay or failure to exercise or enforce any right or provision of these Terms and Conditions of Use shall not constitute or be construed as a waiver of such right to act. We shall not be responsible for any breach of these Terms and Conditions of Use caused by circumstances beyond our control. Nothing in these Terms and Conditions of Use shall be construed to create a joint venture, partnership, or agency relationship between you and us, and neither you or we shall have the right or authority to incur any liability, debt, or cost, or enter into any contracts or other arrangements, in the name of or on behalf of the other.</p>
        </div>
    );
};

export default TermsAndConditionsOfUse;